<template>
  <div class="auth-container">
    <div class="auth-header">
      <template v-for="item in menus">
        <div :key="item.code" v-if="item.show" :class="['auth-menu', currentName == item.code ? 'menu-selected' : '']" @click="menuSelected(item.code)">{{item.name}}</div>
      </template>
    </div>
    <div class="auth-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == currentName && item.show" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import groupList from '@/components/auth/groupList/index'
import ProjectUsers from '@/components/auth/ProjectUsers/index'
export default {
  name: 'FactorySystemIndex',
  components: {
  },
  data() {
    return {
      isCollapsed: false,
      currentName: 'n003',
      menus: [
        { code: 'n003', name: '分区管理', com: groupList, show: false, args: {}, level: 0 },
        { code: 'n004', name: '项目团队管理', com: ProjectUsers, show: false, args: {}, level: 0 },
      ],
      count: 0,
    }
  },
  computed: {
    ...mapState('sys', ['menusId', 'myMenus']),
  },
  mounted: function () {
    this.menusList();
  },
  methods: {
    menusList() {
      this.myMenus.functions.forEach(el => {
        if (this.menusId == el.moduleId) {
          this.menus.forEach(ele => {
            if (ele.code == el.code) {
              ele.show = true;
            }
          })
        }
      });
    },
    menuSelected: function (name) {
      if (this.currentName == name) return;
      this.currentName = name;
    },
  }
}
</script>
<style scoped>
.auth-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.auth-header {
  height: 66px;
  flex: none;
  display: flex;
  align-items: flex-end;
}
.auth-menu {
  height: 42px;
  padding: 0 24px;
  margin: 0 15px 0 0;
  cursor: pointer;
  white-space: nowrap;
  color: #525c76;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 18px;

  line-height: 42px;
  border-radius: 8px 8px 0px 0px;
}
.auth-content {
  height: 400px;
  flex: auto;
  padding: 3px;
  /* background-color: white; */
  background: #f5f6fa;
  border-radius: 0 10px 0 0;
  padding: 24px;
}
.menu-selected {
  color: #3f75ff;
  background: #f5f6fa;
}
</style>
