<template>
  <div class="page-box">
    <vxe-table border ref='xTable1' header-align="center" align="center" :row-config="{isHover: true}" :data="list" :radio-config="{ highlight: true}" class="mt20">
      <vxe-column type="seq" width="60"></vxe-column>
      <vxe-column field="name" title="用户名"></vxe-column>
      <vxe-column field="isOwner" title="创建者" width="70">
        <template #default="{ row }">
          {{row.isOwner?'是':'否'}}
        </template>
      </vxe-column>
      <vxe-column field="isLocked" title="解锁" width="60">
        <template #default="{ row }">
          {{row.isLocked?'解锁':'禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="lockTime" title="锁定时间" width="170"></vxe-column>
      <vxe-column field="roles" title="角色权限"></vxe-column>
      <vxe-column field="groups" title="分区权限"></vxe-column>
      <vxe-column width="300" fixed="right">
        <template slot="header">
          <Button type="success" @click="invitesUsers(true)">邀请用户</Button>
          <Button type="primary" @click="GetProjectInvites()" class="ml10">邀请列表</Button>
          <Button type="primary" @click="getList()" class="ml10">刷新</Button>
        </template>
        <template #default="{ row }">
          <Button type="primary" @click="invitesUsers(false,row)">修改</Button>
          <Button type="warning" @click="UnlockUser(row)" class="ml10" v-if="!row.isLocked">解锁</Button>
          <Button type="error" @click="RemoveProjectUser(row)" class="ml10">移出项目</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <InviteUser v-model="showInviteUser" :item='item' @saved='getList'></InviteUser>
    <GetProjectInvites v-model="showGetProjectInvites"></GetProjectInvites>
  </div>
</template>
<script>
import InviteUser from './InviteUser'
import GetProjectInvites from './GetProjectInvites'
export default {
  name: 'ProjectUsers',
  components: {
    InviteUser,
    GetProjectInvites,
  },
  props: {

  },
  data() {
    return {
      showInviteUser: false,
      showGetProjectInvites: false,
      item: {
        isAdd: false,
        data: {}
      },
      list: [],
    }
  },
  watch: {
  },
  mounted: function () {
    this.getList();
  },
  methods: {
    GetProjectInvites() {
      this.showGetProjectInvites = true
    },
    getList() {
      this.$axios.post(`api/auth/GetProjectUsers`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data)
        }
      });
    },
    UnlockUser(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要解锁[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/UnlockUser`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info('解锁用户成功');
              this.getList();
            }
          });
        }
      });
    },
    RemoveProjectUser(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要将[ ${el.name}] 移出项目团队吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/RemoveProjectUser`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info('设置成功');
              this.getList();
            }
          });
        }
      });
    },
    invitesUsers(isAdd, el) {
      this.showInviteUser = true;
      this.item.isAdd = isAdd;
      if (!isAdd) {
        this.item.data = el;
      }
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
}
</style>
