<template>
  <div class="GetGroupList">
    <div>
      <Button type="primary" @click="SaveGroupInfo(1)" class="mr20">修改分区</Button>
      <Button type="success" @click="SaveGroupInfo(2)" class="mr20">增加顶级分区</Button>
      <Button type="success" @click="SaveGroupInfo(3)" class="mr20">增加选择子级分区</Button>
      <Button type="warning" @click="DeleteGroupInfo()">删除选中分区</Button>
    </div>
    <Tree :data="groupList" ref="groupTree" show-checkbox check-strictly check-directly @on-check-change="handleNodeClick"></Tree>
    <Modal v-model="showModal" :mask-closable="false" width="500px" :title="title">
      <Form ref="form" :model="form" :label-width="100">
        <FormItem prop="name" label="分区名称">
          <Input v-model="form.name"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" @click="showModal=false">取消</Button>
        <Button type="primary" @click="ok">保存</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
// import roleInfoEdit from './roleInfoEdit'
export default {
  name: 'GetGroupList',
  components: {
  },
  data() {
    return {
      groupList: [],
      indexRefresh: 0,
      selectedNode: null,
      showModal: false,
      title: '',
      form: {
        "id": "", //分区ID
        "name": "", //分区名称
        "parentId": "" //上级分区ID，不要选择当前节点及其子节点
      }
    }
  },

  mounted: function () {
    this.GetGroupList();
  },
  methods: {
    SaveGroupInfo(edit) {
      this.showModal = true;
      let chkNodes = this.$refs.groupTree.getCheckedNodes();
      if (edit == 1) {
        this.title = '修改---' + chkNodes[0].name
        this.form.id = chkNodes[0].id;
        this.form.name = chkNodes[0].name;
        this.form.parentId = chkNodes[0].parentId;
      } else if (edit == 2) {
        this.title = '添加顶级分区'
        this.form.id = '';
        this.form.name = '';
        this.form.parentId = '';
      } else {
        this.title = '添加---' + chkNodes[0].name + '---的子级分区'
        this.form.id = '';
        this.form.name = '';
        this.form.parentId = chkNodes[0].id;
      }
    },
    ok() {
      this.$axios.post(`api/auth/SaveGroupInfo`, this.form).then(res => {
        this.showModal = false;
        if (res.code == 0) {
          this.GetGroupList();
        }
      });
    },
    DeleteGroupInfo(el) {
      if (this.groupList.length == 1) {
        this.$Message.warning("不能删除最后一个分区");
        return false;
      }
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除角色[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/DeleteCommonRole`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("删除成功");
              this.GetCommonRoles()
            }
          });
        }
      });
    },
    GetGroupList() {
      this.$axios.post(`api/auth/GetGroupList`, {}).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            el.title = el.name;
            el.expand = true;
          });
          this.$set(this, 'groupList', this.buildTree(res.data));
        }
      });
    },
    handleNodeClick(data, obj) {
      data.forEach(el => {
        el.checked = false
      })
      obj.checked = true;
    },
    buildTree(items, parentId = '') {
      return items
        .filter(item => item.parentId === parentId)
        .map(item => ({
          ...item,
          children: this.buildTree(items, item.id),
        }));
    },
  }
}
</script>
<style scoped>
.GetGroupList {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
}
</style>
