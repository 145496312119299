<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">邀请列表</div>
    <div>
      <vxe-table border height="450" ref='xTable1' :row-config="{isHover: true}" :data="data" :radio-config="{ highlight: true}" :key="indexRefresh">
        <vxe-column field="name" title="姓名"></vxe-column>
        <vxe-column field="mobile" title="手机号"></vxe-column>
        <vxe-column field="inviteTime" title="邀请时间"></vxe-column>
        <vxe-column field="remark" title="备注"></vxe-column>
        <vxe-column field="roleNames" title="角色权限"></vxe-column>
        <vxe-column field="groupNames" title="分区权限"></vxe-column>
        <vxe-column width="80" fixed="right">
          <template #default="{ row }">
            <Button type="warning" @click="RemoveInvite(row)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>

export default {
  name: 'functionInfoEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      data: [],
      indexRefresh: 0
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.GetProjectInvites();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    GetProjectInvites() {
      this.$axios.post(`api/auth/GetProjectInvites`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'data', res.data);
          this.indexRefresh++;
          if (res.data.length == 0) {
            this.showModal = false;
            this.$Message.info('无邀请用户');
          }
        }
      });
    },
    RemoveInvite(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/RemoveInvite`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info('删除邀请用户成功');
              this.GetProjectInvites();
            }
          });
        }
      });
    },
    ok() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
</style>
