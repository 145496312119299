<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{item.isAdd?"邀请信息":`修改---${form.name}---的权限`}}</div>
    <div class="card-box">
      <Card style="width:28%">
        <template #title>
          <Icon type="ios-film-outline"></Icon>
          基本信息
        </template>
        <Form ref="form" :model="form" :label-width="100">
          <FormItem prop="name" label="姓名">
            <Input v-model="form.name" :disabled='!item.isAdd'></Input>
          </FormItem>
          <FormItem prop="mobile" label="手机号">
            <Input v-model="form.mobile" :disabled='!item.isAdd'></Input>
          </FormItem>
          <FormItem prop="remark" label="邀请说明" v-if="item.isAdd">
            <Input v-model="form.remark" type="textarea" :rows="2"></Input>
          </FormItem>
        </Form>
      </Card>
      <Card style="width:35%">
        <template #title>
          <Icon type="ios-film-outline"></Icon>
          操作权限
        </template>
        <div class="">
          <vxe-table border ref="tableRef" height="300" :data="roleIdData" :key="roleIdDataIndex">
            <vxe-column type="radio" width="60"></vxe-column>
            <vxe-column field="name" title="名称"></vxe-column>
          </vxe-table>
        </div>
      </Card>
      <Card style="width:35%">
        <template #title>
          <Icon type="ios-film-outline"></Icon>
          数据权限
        </template>
        <div style="height: 500px;overflow-x: hidden;overflow-y: auto;border: solid 0 red">
          <Tree ref="groupTree" :data="groupIdData" show-checkbox check-strictly check-directly @on-check-change="noceCheckChanged"></Tree>
        </div>
      </Card>

    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>

export default {
  name: 'InviteUser',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      roleIdData: [],
      groupIdData: [],
      roleIdDataIndex: 0,
      checkRowKey: 0,
      form: {
        "id": '',
        "mobile": "", //邀请的用户手机号
        "name": "",
        "remark": "", //邀请说明
        "roleId": "", //赋予的角色Id
        "groupIds": [],
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.parameterAjax();
        if (this.item.isAdd) {
          this.form = {
            "id": '',
            "mobile": "", //邀请的用户手机号
            "name": "",
            "remark": "", //邀请说明
            "roleId": "", //赋予的角色Id
            "groupIds": [],
          }
        } else {
          this.form = {};
          this.$set(this, 'form', this.item.data)
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    noceCheckChanged: function (nodes, node) {
      if (!node.checked) return;
      this.unchecksubs(node);
      for (let n of nodes) {
        let subIds = this.getSubNodeIds(n);
        if (subIds.includes(node.id)) {
          this.$set(n, 'checked', false);
        }
      }
    },
    getSubNodeIds: function (node) {
      let ids = [];
      for (let n of node.children) {
        ids.push(n.id);
        ids.push(...this.getSubNodeIds(n));
      }
      return ids;
    },
    unchecksubs: function (node) {
      if (!node.children) return;
      for (let n of node.children) {
        // n.checked = false;
        this.$set(n, 'checked', false);
        this.unchecksubs(n);
      }
    },

    parameterAjax() {
      this.$axios.post(`api/auth/GetProjectRoles`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'roleIdData', res.data);
          this.roleIdDataIndex++;
          if (this.item.isAdd) return;
          setTimeout(() => {
            this.$refs.tableRef.tableData.forEach(row => {
              if (row.id == this.item.data.roleIds[0]) {
                this.$refs.tableRef.setRadioRow(row)
              }
            })
          }, 200);
        }
      });
      this.$axios.post(`api/auth/GetProjectGroups`, {}).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            el.title = el.name;
            if (!this.item.isAdd) {
              if (this.item.data.groupIds.indexOf(el.id) > -1) {
                el.checked = true;
              }
            }

          });
          this.$set(this, 'groupIdData', this.buildTree(res.data))
        }
      });
    },
    buildTree(items, parentId = '') {
      return items
        .filter(item => item.parentId === parentId)
        .map(item => ({
          ...item,
          children: this.buildTree(items, item.id),
        }));
    },
    isPhone() {
      let pattern = /^1[3456789]\d{9}$/;
      return pattern.test(this.form.mobile);
    },
    ok() {
      if (this.form.name == '') {
        this.$Message.warning('请填写姓名');
        return false
      }
      if (!this.isPhone() && this.item.isAdd) {
        this.$Message.warning('请填写邀请者手机号');
        return false
      }
      let role = this.$refs.tableRef.getRadioRecord();
      if (role == null) {
        this.$Message.warning('请选择操作权限');
        return false
      }
      this.form.roleId = role.id;
      let chkNodes = this.$refs.groupTree.getCheckedNodes();
      let userGroups = [];
      for (let cn of chkNodes) {
        userGroups.push(cn.id);
      }
      if (userGroups.length == 0) {
        this.$Message.warning('请选择数据权限');
        return;
      }
      this.form.groupIds = userGroups;
      //  
      this.$axios.post(`api/auth/${this.item.isAdd ? 'InviteUser' : 'SetUserAgent'}`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.item.isAdd ? "邀请" : "设置" + '成功');
          this.$emit("saved")
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.card-box {
  height: 600px;
  display: flex;
  justify-content: space-around;
}
</style>
